import { graphql } from "gatsby";
import React, { Fragment } from "react";
import ArticleA from "../components/ArticleA";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import LocalScript from "../components/LocalScript";
import LocalScript2 from "../components/LocalScript2";
import { SEO } from "../components/SEO";

const Article = ({ data: { sanityArticle, sanitySite }, location }) => {
  return (
    <Fragment>
      <LocalScript2
      // isHeadScript
      // html={`!function(e,t){(e=t.createElement("script")).src="https://cdn.convertbox.com/convertbox/js/embed.js",e.id="app-convertbox-script",e.async=true,e.dataset.uuid="a1ce5f9e-d630-4668-be5b-3554f61e94a7",document.getElementsByTagName("head")[0].appendChild(e)}(window,document);`}
      // firstRemoveScript="https://cdn.convertbox.com/convertbox/js/embed.js"
      />
      {/* <LocalScript
        html={`
            var disqus_config = function () {
              this.page.url = 'https://nickwolny.com/${location?.pathname}';
              this.page.identifier = '${sanityArticle?.id}'; 
            };
            (function() {
            var d = document, s = d.createElement('script');
            s.src = 'https://nick-wolny.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
          `}
      /> */}
      {/* <Script
        dangerouslySetInnerHTML={{
          __html: `
            var disqus_config = function () {
              this.page.url = 'https://nickwolny.com/${location?.pathname}';
              this.page.identifier = '${sanityArticle?.id}'; 
            };
            (function() {
            var d = document, s = d.createElement('script');
            s.src = 'https://nick-wolny.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
          `,
        }}
      /> */}
      <Layout headerData={sanitySite?.header}>
        <ArticleA article={sanityArticle} />
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Article;
export const Head = ({ data: { sanityArticle } }) => {
  return <SEO metadata={sanityArticle?.pageMetadata || {}} />;
};
export const query = graphql`
  query ($categoryId: String!, $articleId: String!) {
    sanityArticle(
      category: { elemMatch: { id: { eq: $categoryId } } }
      id: { eq: $articleId }
    ) {
      featuredImage {
        asset {
          extension
          url
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
      _rawFeaturedImageattribution
      templateType
      slug {
        current
      }
      pageMetadata {
        schema
        description
        keywords
        title
        canonical
        image {
          asset {
            url
          }
        }
      }
      heading
      id
      category {
        slug {
          current
        }
        title
        id
        description
      }
      author {
        name
        id
        email
        bio
      }
      _rawContent
      tableContent {
        title
        items
      }
      _createdAt(formatString: "MMMM DD YYYY")
      _updatedAt(formatString: "MMMM DD YYYY")
      readTime
      summary
      sidebarImages {
        ... on SanityArticleImage {
          _type
          alt
          anchorLink
          title
          asset {
            gatsbyImageData
          }
        }
        ... on SanityRelatedLinks {
          links {
            type
            label
            path
            url
          }
          _type
        }
        ... on SanityCodeBlock {
          _type
          code
        }
        ... on SanityAdBlock {
          _type
          slot
        }
      }
    }
    sanitySite {
      header {
        links {
          label
          path
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
      }
    }
  }
`;
