import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";

export default function Hero({ data }) {
  const [acLoading, setACLoading] = useState(false);
  const acFormRef = useRef(null);
  const [acForm, setACForm] = useState({});
  const [acFormErrors, setACFormErrors] = useState([]);
  const [acFormIsSuccessful, setACFormIsSuccessful] = useState(false);

  const _subscribeToActiveCampaign = async (e) => {
    e.preventDefault();
    setACFormErrors([]);
    setACLoading(true);
    setACFormIsSuccessful(false);

    let _fetch = await fetch(
      "/.netlify/functions/subscribe-to-active-campaign",
      {
        method: "POST",
        body: JSON.stringify({
          // name: acForm.name,
          email: acForm.email,
          site_url: "www.nickwolny.com/newsletter",
        }),
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      }
    );

    console.log(_fetch);
    if (_fetch.ok) {
      let data = await _fetch?.json();
      Array.from(e.target.elements).forEach(function (el) {
        el.value = "";
      });
      setACFormIsSuccessful(true);
      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: "generate_lead",
        });
      }
      setTimeout(() => {
        setACFormIsSuccessful(false);
      }, 3000);
    } else {
      let data = await _fetch.json();
      if (_fetch.status === 422) {
        setACFormErrors(data.errors);
      }
    }

    setACLoading(false);
  };

  return (
    <section className="relative sm:h-[640px] h-[740px]">
      <div className="absolute top-0 bottom-0 left-0 right-0 -z-20">
        <GatsbyImage
          image={data.thumbnail.asset.gatsbyImageData}
          alt="newsletter-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px] w-full bg-cover"
        />
      </div>
      <div className="container h-full text-black">
        <div className="flex items-center h-full">
          <form
            onSubmit={_subscribeToActiveCampaign}
            ref={acFormRef}
            className="max-w-[674px]"
          >
            <h1 className="text-[35px] sm:text-[64px] sm:leading-[82px] leading-[52px] font-lora font-medium">
              {data?.title}
            </h1>

            <h2 className="mt-8 text-base sm:text-[20px] leading-[25px]">
              {data?.caption}
            </h2>
            <div className="mt-8">
              <div className={"grid grid-cols-5 gap-4"}>
                {/* <div className={"col-span-2"}>
                        <input
                          type={"text"}
                          className={
                            "border border-gray-200 bg-transparent rounded-lg py-3 px-4 w-full"
                          }
                          placeholder={"First Name"}
                          defaultValue={acForm.name}
                          onChange={(e) =>
                            setACForm({ ...acForm, name: e.target.value })
                          }
                          required
                          disabled={acLoading}
                        />
                      </div> */}
                <div className={"col-span-4"}>
                  <label className="text-sm" htmlFor="email">
                    Email*
                  </label>
                  <input
                    id={"email"}
                    type={"email"}
                    className={`border ${
                      acFormErrors.length > 0
                        ? "border-red-500"
                        : "border-gray-500"
                    } bg-transparent rounded-lg py-3 px-4 w-full mt-2`}
                    placeholder={"Type your email"}
                    defaultValue={acForm.email}
                    onChange={(e) =>
                      setACForm({ ...acForm, email: e.target.value })
                    }
                    required
                    disabled={acLoading}
                  />
                  <p className={"text-red-500 text-sm mt-1"}>
                    {acFormErrors.map((e) => e.title).join(", ")}
                  </p>
                </div>
              </div>
              <div className={"flex items-center mt-5"}>
                <button
                  className={
                    "transition-all duration-300 bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg"
                  }
                  type={"submit"}
                  disabled={acLoading}
                >
                  {acLoading ? "Signing up..." : "Sign Up"}
                </button>
                {acFormIsSuccessful && (
                  <p className={"text-green-400 ml-3 mb-0"}>
                    You have been signed up successfully.
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
