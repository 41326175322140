import { PortableText } from "@portabletext/react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { useSite } from "../hooks/use-site";
import PortableContent from "./PortableContent";
import ScrollProgress from "./ScrollProgress";
import TableContents from "./TableContents";
import AdBanner from "./adBanner";
import LocalScript from "./LocalScript";

let options = {
  root: null,
  rootMargin: "0% 0% -50% 0%",
  threshold: 0,
};

const ArticleA = ({ article }) => {
  const site = useSite();

  let previous = "";
  // let counter = 0;

  useEffect(() => {
    const sections = Array.from(
      document.querySelectorAll("h1, h2, h3, h4, h5")
    );

    const scrollHandler = (entries) => {
      return entries.forEach((entry) => {
        const section = entry.target;
        const sectionId = section?.id;

        const sectionLink = document.querySelector(`a[href="#${sectionId}"]`);
        const previousLink = document.querySelector(`a[href="#${previous}"]`);

        // const index = sections.findIndex((i) => i.id === sectionId);

        if (entry.isIntersecting) {
          sectionLink?.classList.add("item-visible");

          // if (counter < 2) {
          //   counter += 1;
          // }

          if (previous !== sectionId) {
            previousLink?.classList.remove("item-visible");
          }
          previous = sectionId;
        } else {
          // previous = sectionId;

          // if (counter > 0 && counter <= 1) {
          //   counter += 1;
          // }

          // if (counter === 2) {
          //   counter = 0;
          // }

          if (previous !== sectionId) {
            sectionLink?.classList.remove("item-visible");
          }
          // if (index === sections.length) {
          //   console.log("===max", index);
          //   previous = "";
          // }
        }
      });
    };
    // Creates a new scroll observer
    const observer = new IntersectionObserver(scrollHandler, options);

    sections.forEach((section) => observer.observe(section));
  }, []);

  return (
    <div className="flex max-w-[1300px] mx-auto">
      {article?.tableContent && <TableContents data={article?.tableContent} />}
      <ScrollProgress />
      <div className={"container grid grid-cols-3 gap-6 px-3 mt-10 article"}>
        <div className={"col-span-3 md:col-span-2"}>
          <div
            className={
              "bg-black/[.02] rounded-[33px] rounded-b-[33px] lg:rounded-b-none px-5 lg:px-10 pt-8 lg:pt-[59px] pb-[45px] lg:pb-[90px]"
            }
          >
            <div className="flex flex-wrap text-sm font-lora">
              <div>
                <Link
                  className="text-[#AF872D] hover:underline"
                  to={`/blog/${article.category[0]?.slug?.current}`}
                >
                  {article.category[0]?.title}
                </Link>
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>
                Reading Time: {article?.readTime ? article?.readTime : 5}{" "}
                minutes
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>Last Updated On: {article?._updatedAt}</div>
            </div>
            <h1 className={"text-[32px] leading-[41px] mt-6 text-black"}>
              {article.heading}
            </h1>
            {article?.summary && (
              <div className="mt-4">
                <p className="text-sm font-lora opacity-70 article-summary">
                  {article?.summary}
                </p>
              </div>
            )}
            <div className="mt-[55px]">
              {article?.featuredImage?.asset?.extension === "image" ? (
                <GatsbyImage
                  className="w-full rounded-[14px]"
                  image={article?.featuredImage?.asset?.gatsbyImageData}
                  imgClassName="w-full"
                  alt={article?.heading}
                />
              ) : (
                <div className="w-full">
                  <img
                    className="w-full"
                    src={article?.featuredImage?.asset?.url}
                  />
                </div>
              )}
              {article?._rawFeaturedImageattribution && (
                <div className="prose max-w-none">
                  <div className="mt-2 mb-4 text-sm leading-6 text-[#676a6b] text-center attribution">
                    <PortableText
                      value={article?._rawFeaturedImageattribution}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-[26px] article-body">
              <div className="text-base leading-5 prose max-w-none">
                <PortableContent article={article} />
              </div>
            </div>
            {/* <div className="mt-20">
              <div id="disqus_thread"></div>
            </div> */}
          </div>
        </div>
        <div className={"col-span-3 md:col-span-1"}>
          <div className={"bg-black/[.02] rounded-[33px] p-8"}>
            <div className={"flex"}>
              <GatsbyImage
                image={site?.aboutNick?.image?.asset?.gatsbyImageData}
                alt={"Nick"}
                className={"mx-auto"}
              />
              {/*<StaticImage*/}
              {/*	src={"../images/articles/nick.png"}*/}
              {/*	alt={"Nick"}*/}
              {/*	height={150}*/}
              {/*	className={"mx-auto"}*/}
              {/*/>*/}
            </div>
            <p className={"font-lora text-xs mt-5"}>
              {/*Nick Wolny is the senior editor of financial independence for*/}
              {/*NextAdvisor, a writer, conference speaker, business consultant, and*/}
              {/*leading voice on LGBTQ capitalism. A professional classical musician*/}
              {/*by training, he leverages the concepts of deliberate practice and*/}
              {/*performance to help you unlock creativity and make more brilliant*/}
              {/*decisions.*/}
              {site?.aboutNick?.bio}
            </p>
          </div>
          {/* <div className="mb-10 _form_47"></div>
          <Script
            src="https://lessnoisemorevolume.activehosted.com/f/embed.php?id=47"
            type="text/javascript"
            charSet="utf-8"
            id={`ac${Math.random()}`}
          ></Script> */}
          {article?.sidebarImages?.length > 0 && (
            <div
              className={
                "bg-black/[.02] rounded-[33px] text-center p-8 h-full mb-10 mt-10"
              }
            >
              {article?.sidebarImages?.map((item, i) => {
                if (item?._type === "articleImage") {
                  return (
                    <a
                      href={item?.anchorLink}
                      target={"_blank"}
                      className={`no-underline block mb-4 ${
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }`}
                      rel="noreferrer noopener"
                      key={i}
                    >
                      <GatsbyImage
                        image={item?.asset?.gatsbyImageData}
                        alt={item?.alt}
                      />
                    </a>
                  );
                } else if (item?._type === "codeBlock") {
                  return (
                    <div
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                      key={i}
                      dangerouslySetInnerHTML={{ __html: item?.code }}
                    />
                  );
                } else if (item?._type === "adBlock") {
                  return (
                    <div
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                    >
                      <AdBanner slot={item?.slot} key={i} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`text-left ${
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }`}
                      key={i}
                    >
                      <h4 className="mb-2 text-xl font-medium">
                        Related Links
                      </h4>
                      {item?.links?.map((link, i) => {
                        if (link?.type === "internal") {
                          return (
                            <Link
                              to={link?.path}
                              className="block underline font-ssp"
                              key={i}
                            >
                              {link?.label}
                            </Link>
                          );
                        } else {
                          return (
                            <a
                              href={link?.url}
                              target={"_blank"}
                              className={"underline block mt-2 font-ssp"}
                              rel="noreferrer noopener"
                              key={i}
                            >
                              {link?.label}
                            </a>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </div>
          )}

          {/*<div className={"bg-black rounded-[33px] p-8 my-5 text-white"}>*/}
          {/*	<h3 className={""}>*/}
          {/*		Finally dial in your article-writing game with the Article Template*/}
          {/*		Toolkit*/}
          {/*	</h3>*/}
          {/*	<p className={"text-xs font-lora mt-5"}>*/}
          {/*		This free, 4-resource toolkit includes 9 easy-to-follow article*/}
          {/*		frameworks, a downloadable spreadsheet to keep your article scraps*/}
          {/*		organized, a screen-recorded walkthrough video, and a PDF teardown*/}
          {/*		of an exact article I wrote for Entrepreneur.com.*/}
          {/*	</p>*/}

          {/*	<input*/}
          {/*		type={"text"}*/}
          {/*		className={*/}
          {/*			"border border-gray-600 bg-[#121212] rounded-lg py-3 px-4 w-full mt-5 text-sm"*/}
          {/*		}*/}
          {/*		placeholder={"Name"}*/}
          {/*	/>*/}

          {/*	<input*/}
          {/*		type={"text"}*/}
          {/*		className={*/}
          {/*			"border border-gray-600 bg-[#121212] rounded-lg py-3 px-4 w-full mt-2 text-sm"*/}
          {/*		}*/}
          {/*		placeholder={"Email"}*/}
          {/*	/>*/}

          {/*	<button*/}
          {/*		className={*/}
          {/*			"transition-all duration-300 bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11 rounded-lg mt-5 w-full"*/}
          {/*		}*/}
          {/*	>*/}
          {/*		Send Me Toolkit*/}
          {/*	</button>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default ArticleA;
